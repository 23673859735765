.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.color-header {
  background-image: url("assets/StarworksHeader.png");
  background-size: cover;
}

.App-backdrop {
  /* background-image: url("assets/StarStuBgCrop.png"); */
  /* background-size: cover; */
  background-color: #282c34;
  min-height: calc(100vh - 86px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  width: "100vw";
  background-color: #16181d;
  color: white;
  display: flex;
  font-size: calc(10px + 2vmin);
  flex-direction: column;
  align-items: center;
}

.footerLink {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}

.vertical-center {
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.Header-logo {
  margin: 5px 20px;
  height: 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inverted {
  filter: invert(1);
}

.adminCardIcon {
  padding: 5rem;
}

/* Member bios */

.memberBio {
  width: 40rem;
  height: 15rem;
  padding: 1rem;
  margin: 1rem;
  text-align: left;
}

.memberBioLeft {
  border-radius: 12rem 3rem 3rem 12rem;
}

.memberBioRight {
  border-radius: 3rem 12rem 12rem 3rem;
}

.bioImg {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin: 0.5rem;
}

/* Author By-Line */

.authorComp {
  width: fit-content;
  max-width: 100%;
  height: 99px;
  padding: 1rem 2rem 1rem 1rem;
  margin: 0.5rem;
  text-align: left;
  border-radius: 12rem 12rem 12rem 12rem;
}

.authorImg {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0.1rem 0.5rem 0.1rem 0.1rem;
}

/* Blog Stuff */

.blogPost {
  font-size: calc(2px + 2vmin);
}

.blogPostImg {
  max-width: 800px;
  margin: auto;
}

.blogPostList {
  cursor: pointer;
  height: 12rem;
  background-color: #383e48;
}
.blogPostList.top {
  border-radius: 2rem 2rem 0rem 0rem;
}
.blogPostList.bottom {
  border-radius: 0rem 0rem 2rem 2rem;
}
.blogPostList.alone {
  border-radius: 2rem;
}

.blogPostList:hover {
  background-color: #454c58;
}

.blogPostFeature {
  cursor: pointer;
  height: 20rem;
  width: 100%;
  max-width: 26rem;
  border-radius: 2rem;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

@keyframes postFeatureHover {
  from {
    height: 16rem;
  }
  to {
    height: 4rem;
  }
}

.blogPostFeatureSpacer {
  height: 16rem;
}

.blogPostFeature:hover > .blogPostFeatureSpacer {
  animation-name: postFeatureHover;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.blogPostFeatureText {
  background-color: rgb(0, 0, 0, 0.8);
  height: 4rem;
  flex-grow: 1;
  overflow: hidden;
  border-radius: 0rem 0rem 2rem 2rem;
  padding: 0.6rem 1rem;
}

/* Youtube Embed */

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Starworks Button */
.starworks-button {
  display: flex;
  background-image: url("assets/StarworksButton.png");
  background-size: contain;
  height: 50px;
  aspect-ratio: calc(624 / 158);
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  margin: 1px 5px;
}

.no-underline {
  text-decoration: none;
}

/* Redesign */
.dotted-backdrop {
  background-image: url("assets/StarStuBgCrop.png");
  background-size: cover;
}

.color-backdrop {
  background-image: url("assets/StarworksSection.png");
  background-size: cover;
}

.img-responsive {
  height: auto;
  width: 100%;
}
